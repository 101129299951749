import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoute } from "../private-routes";
import Loader from "../components/Loader/Loader";
import AbsoluteLoader from "../components/Loader/AbsoluteLoader";

const Homepage = lazy(() => import("../pages/Home/Homepage"));
const Login = lazy(() => import("../Authenticate/Login/Login"));
const SignUp = lazy(() => import("../Authenticate/SignUp/SignUp"));
const AboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const Blog = lazy(() => import("../pages/Blog/Blog"));
const ViewBlog = lazy(() => import("../pages/ViewBlog/ViewBlog"));
const FindDentist = lazy(() => import("../pages/FindDentist/FindDentist"));
const Support = lazy(() => import("../pages/Support/Support"));
const ForgotPassword = lazy(() => import("../Authenticate/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../Authenticate/ResetPassword/ResetPassword"));
const Questionnair = lazy(() => import("../pages/Questionnair/Questionnair"));
const Recommendation = lazy(() => import("../components/Recommendation/Recommendation"));
const RecentSearch = lazy(() => import("../pages/RecentSearch/RecentSearch"));
const TermsOfUse = lazy(() => import("../pages/Terms-of-Use/TermsOfUse"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy/PrivacyPolicy"));
const Performance = lazy(() => import("../pages/ProviderHomepage/Performance/Performance"));
const DentistLists = lazy(() => import("../pages/Dentist-Lists/DentistLists"));
const Appointments = lazy(() => import("../pages/Appointments/Appointments"));
const DentistList = lazy(() => import("../pages/Dentist-List/DentistList"));
const DentistProfile = lazy(() => import("../pages/Dentist-Profile/DentistProfile"));
const DoctorList = lazy(() => import("../pages/ProviderHomepage/Doctor/DoctorList"));
const DoctorProfile = lazy(() => import("../pages/ProviderHomepage/Doctor/DoctorProfile"));
const ProviderProfile = lazy(() => import("../pages/ProviderHomepage/ProviderProfile/ProviderProfile"));
const Dashboard = lazy(() => import("../pages/ProviderHomepage/Dashboard/Dashboard"));
const ProviderChatBox = lazy(() => import("../pages/ProviderHomepage/ProviderChat-box/ProvdiderChatBox"));
const AppointmentRequest = lazy(() => import("../pages/ProviderHomepage/AppointmentRequest/AppointmentRequest"));
const Billings = lazy(() => import("../pages/ProviderHomepage/Billings/Billings"));
const ProviderHomepage = lazy(() => import("../pages/ProviderHomepage/ProviderHomepage"));
const Calender = lazy(() => import("../pages/ProviderHomepage/Calender/Calender"));
const ChatBox = lazy(() => import("../pages/Chat-box/ChatBox"));
const UserProfile = lazy(() => import("../pages/UserProfile/UserProfile"));

export default function AllRoute() {
  return (
    <Suspense fallback={ <AbsoluteLoader loading={true} />}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/view-blog" element={<ViewBlog />} />
        <Route path="/find-dentist" element={<FindDentist />} />
        <Route path="/support" element={<Support />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:data" element={<ResetPassword />} />
        <Route path="/questionnair" element={<Questionnair />} />
        <Route path="/recommendation" element={<Recommendation />} />
        <Route path="/recentSearch" element={<RecentSearch />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/privacy-&-policy" element={<PrivacyPolicy />} />
        <Route path="/performance" element={<Performance />} />
        <Route path="/dentist-lists" element={<DentistLists />} />
        <Route path="/appointments/:id/:from?" element={<Appointments />} />
        <Route path="/dentist-list" element={<PrivateRoute account_type="user"><DentistList /></PrivateRoute>} />
        <Route path="/dentistProfile" element={<DentistProfile />} />
        <Route path="/doctor-list" element={<PrivateRoute account_type="user"><DoctorList /></PrivateRoute>} />
        <Route path="/doctor-profile" element={<PrivateRoute account_type="clinic"><DoctorProfile /></PrivateRoute>} />
        <Route path="/provider-profile/:id" element={<PrivateRoute account_type="clinic"><ProviderProfile /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute account_type="clinic"><Dashboard /></PrivateRoute>} />
        <Route path="/provider-chat-box" element={<PrivateRoute account_type="clinic"><ProviderChatBox /></PrivateRoute>} />
        <Route path="/appointment-request" element={<PrivateRoute account_type="clinic"><AppointmentRequest /></PrivateRoute>} />
        <Route path="/billings" element={<PrivateRoute account_type="clinic"><Billings /></PrivateRoute>} />
        <Route path="/providers" element={<PrivateRoute account_type="clinic"><ProviderHomepage /></PrivateRoute>} />
        <Route path="/Provider-Calender" element={<PrivateRoute account_type="clinic"><Calender /></PrivateRoute>} />
        <Route path="/chat-box" element={<PrivateRoute account_type="user"><ChatBox /></PrivateRoute>} />
        <Route path="/user-profile/:tab" element={<PrivateRoute account_type="user"><UserProfile /></PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}
