import axios from "axios";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setAuthenticated } from "../../redux/slices/auth/index.js";
import { store } from "../../redux/store.jsx";
import {
  showLoader,
  hideLoader,
  setProgress,
} from "../../redux/slices/loader/index.js";

const BASE_URL = process.env.REACT_APP_SERVER_URL;
// console.log("env", process.env.REACT_APP_SERVER_URL, process.env);

injectStyle();

export function errorNotification(message) {
  toast.error(message);
}

export function successNotification(message) {
  toast.success(message);
}

export function getToken() {
  return localStorage.getItem("token");
  // if (
  //   localStorage.getItem("remember_me") &&
  //   localStorage.getItem("remember_me") == "yes"
  // ) {
  //   return localStorage.getItem("token");
  // }
  // if (
  //   localStorage.getItem("remember_me") &&
  //   localStorage.getItem("remember_me") == "no"
  // ) {
  //   return sessionStorage.getItem("token");
  // } else {
  //   return null;
  // }
}

export async function isAuthenticated() {
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "yes"
  ) {
    return localStorage.getItem("isLogged") == "true";
  }
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "no"
  ) {
    return localStorage.getItem("isLogged") == "true";
  } else {
    return false;
  }
}

export function loggedUserDetails() {
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "yes"
  ) {
    return JSON.parse(localStorage.getItem("user_details"));
  }
  if (localStorage.getItem("remember_me")) {
    return JSON.parse(sessionStorage.getItem("user_details"));
  } else {
    return null;
  }
}

function objToQueryString(obj) {
  const searchParams = new URLSearchParams();
  const params = obj;
  Object.keys(params).forEach((key) => searchParams.append(key, params[key]));
  return searchParams.toString();
}

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      // if token avaiable
      config.headers["Authorization"] = "Bearer " + token;
    }
    if(!config.url.includes("landing") && !config.url.includes("getFaqs") && !config.url.includes("getContactDetails") && !config.url.includes("getHomeContent")){
      store.dispatch(showLoader());
    }
    return config;
  },
  (error) => {
    store.dispatch(hideLoader());
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    store.dispatch(hideLoader());
    return response;
  },
  (error) => {
    store.dispatch(hideLoader());
    if (error.message === "Network Error") {
      errorNotification(error.message);
    }
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data === "Unauthorized"
    ) {
      window.alert("Session Expired, Plesse login again");
      localStorage.clear();
      sessionStorage.clear();

      window.location.reload();
    } else {
      if (typeof error?.response?.data == "string") {
        console.log("error data", error?.response?.data);
      } else if (typeof error?.response?.data == "object") {
        console.log("error data", error?.response?.data?.errors?.msg);
      }
    }
    return Promise.reject(error);
  }
);

axios.defaults.onDownloadProgress = (event) => {
  if (event.total) {
    const progress = Math.round((event.loaded / event.total) * 100);
    store.dispatch(setProgress(progress));
  }
};

export async function signupService(body) {
  try {
    const resp = await generateImageFromName(body?.first_name);
    body.profile_image = resp;
    return signupApiCall(body);
  } catch (error) {
    console.log("error in generating image");
    return signupApiCall(body);
  }
}

async function signupApiCall(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/register`, body);
    return res;
  } catch (error) {
    errorNotification(error.response?.data?.msg);
    throw error;
  }
}

export async function loginService(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/login`, body);
    store.dispatch(setAuthenticated(true));
    return res.data;
  } catch (error) {
    console.log("error response", error);
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function forgotPassword(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/sendForgotPasswordEmail`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getProfilesDetail() {
  // const data = objToQueryString(body);
  try {
    var res = await axios.get(`${BASE_URL}users/getUserProfile`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function changePassword(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/change/password`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function imageUpload(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/uploadUserMedia`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function unseenMessagesCount() {
  try {
    var res = await axios.get(`${BASE_URL}users/unseenMessageCount`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function downloadImage(url) {
  try {
    var res = await axios.get(`${process.env.REACT_APP_RECEIPT}${url}`, {
      responseType: "blob",
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function downloadBillingReceipt(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/downloadInvoice`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function downloadedBillingReceipt(url) {
  try {
    var res = await axios.get(url, {
      responseType: "blob",
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function editUserProfile(body) {
  try {
    var res = await axios.patch(`${BASE_URL}users/editUserProfile`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function addMedicalRecord(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/addMedicalRecord`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getMedicalRecordsList(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/medicalRecordListing?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function editMedicalRecordService(body, medical_record_Id) {
  try {
    var res = await axios.patch(
      `${BASE_URL}users/editMedicalRecord/${medical_record_Id}`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function deleteMedicalRecord(medical_record_Id) {
  try {
    var res = await axios.delete(
      `${BASE_URL}users/deleteMedicalRecord/${medical_record_Id}`
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function editMedicalRecordById(medical_record_Id) {
  try {
    var res = await axios.get(
      `${BASE_URL}users/getMedicalRecord/${medical_record_Id}`
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function aboutUsService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getAboutUsContent`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function supportPageService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getSupportContent`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function faqQuestions(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/getFaqs?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDentistContentService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getDentistContent`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function socialSignUpService(body) {
  try {
    const respForDp = await generateImageFromName(body?.first_name);
    if (respForDp) {
      body.profile_image = respForDp;
    }
    var res = await axios.post(`${BASE_URL}auth/social/login`, body);
    store.dispatch(setAuthenticated(true));

    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function slotBooking(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/bookAppointment`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function slotBookingForReshedule(body) {
  try {
    var res = await axios.post(
      `${BASE_URL}users/bookAppointmentInReschedule`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function declineAllSuggestedSlots(body) {
  try {
    var res = await axios.patch(
      `${BASE_URL}users/cancelAppointmentByUser`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function requestForAnotherReschedule(body) {
  try {
    var res = await axios.patch(
      `${BASE_URL}users/rescheduleAppointmentRequestByUser`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}
export async function getBranchListing() {
  try {
    var res = await axios.get(`${BASE_URL}users/getBranchListing`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function addDentistService(body) {
  try {
    if (!body.profile_image) {
      const first_name = body?.first_name.slice(4);
      const respForDp = await generateImageFromName(first_name);
      if (respForDp) {
        body.profile_image = respForDp;
      }
    }
    var res = await axios.post(`${BASE_URL}users/addDentist`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getBranchByIdService(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/getSingleBranch/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getHomeContentService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getHomeContent`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getPrivacyPolicyService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getPrivacyPolicy`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function download(url) {
  window.open(`${url}`);
}

export async function getTermOfUserService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getTermsOfUse`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function resetForgotPasswordService(body) {
  try {
    var res = await axios.patch(`${BASE_URL}auth/resetForgotPassword`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDentistListingService(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/getDentistListing?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getClinicProfileService() {
  try {
    var res = await axios.get(`${BASE_URL}users/getClinic`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function updateClinicBrnachProfile(body, id) {
  try {
    var res = await axios.patch(
      `${BASE_URL}users/editBranchDetails/${id}`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getNearestClinics(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/searchClinic`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getClinicsData(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/getClinicDetails/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getPatientsExper(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/getRating`, { params: params });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getProviderLists(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/dentistList/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getSlot(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/dentistslots/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function bookedSlot(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/bookedSlots`, {
      params: params,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function disabledSlot(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/bookedSlots`, {
      params: params,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getFavoutiteClinicList() {
  try {
    var res = await axios.get(`${BASE_URL}users/getFavouriteDentist`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function favouriteStatusChanger(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/addOrRemoveFavourite`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getNotifications(param) {
  try {
    var res = await axios.get(`${BASE_URL}users/getNotification`, {
      params: param,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function unSeenNotifications() {
  try {
    var res = await axios.get(`${BASE_URL}users/unseenNotificationCount`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function seenNotifications() {
  try {
    var res = await axios.post(`${BASE_URL}users/seenNotification`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getNextAppointmentData() {
  try {
    var res = await axios.get(`${BASE_URL}users/nextAppointment`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getAppointmentHistoryData(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/getUserBookedAppointment`, {
      params: params,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getSingleAppointmentData(id) {
  try {
    console.log("getSingleAppoi", id);
    var res = await axios.get(`${BASE_URL}users/getAppointment/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function uploadReceipt(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/uploadReceipt`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDefaultSideClinics(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/getRooms/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getChatData(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/getChats`, { params: params });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function addSearchingDetails(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/addQuestionire`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function disabledSlots(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/public/bookedSlots`, {
      params: params,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function addQuestionnaireToEmail(body) {
  try {
    var res = await axios.post(
      `${BASE_URL}users/addQuestionireWithEmail`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function getSlott(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/public/dentistslots/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDentistSlotForSuggestion(id) {
  try {
    var res = await axios.get(
      `${BASE_URL}users/dentistslotsForSlotSuggestion/${id}`
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function getNearestClinic(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/public/searchClinic`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function getClinicData(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/public/getClinicDetails/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function getPatientExper(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/public/getRating`, {
      params: params,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function getProviderList(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/public/dentistList/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//Public
export async function bookedSlots(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/public/bookedSlots`, {
      params: params,
    });
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//export async function getSlot(id)
export async function getSlots(id, day, date, dateString) {
  try {
    var res = await axios.get(
      `${BASE_URL}users/public/dentistslots/${id}?day=${day}&date=${date}&dateString=${dateString}`
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function login_OtpVerify(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/verifyOtp`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function resend_loginOtp(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/resendOtp`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function twoStepVerificationSetting() {
  try {
    var res = await axios.post(`${BASE_URL}auth/changeTwoStepSetting`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function editDentistProfile(body, id) {
  try {
    var res = await axios.patch(`${BASE_URL}users/editDentist/${id}`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function downloadInvoiceReceipt(chargedId) {
  try {
    var res = await axios.get(
      `${BASE_URL}users/downloandPaymentInvoice/${chargedId}`
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function deleteDentistProfile(id) {
  try {
    var res = await axios.delete(`${BASE_URL}users/deleteDentist/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDentistProfileByIdService(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/getSingleDentist/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getcontctdetails() {
  try {
    var res = await axios.get(`${BASE_URL}users/getContactDetails`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function timeSlotRemoveCheck(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/isSlotValidToRemove`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDentistAppointsByIDService(obj) {
  try {
    var res = await axios.post(`${BASE_URL}users/getDentistAppointment`, obj);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getAppointmentDetailById(id) {
  try {
    let value;
    if (id._id) {
      value = id._id;
    } else {
      value = id;
    }
    console.log("getAppointmentDettailBYI", value);
    var res = await axios.get(`${BASE_URL}users/getAppointment/${value}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getBookedAppointmentsByDentist(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/bookedSlots?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboardTopCard_totalAppointment(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/totalAppointments/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboardTopCard_totalPatients(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/totalPatients/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboardTopCard_pendingAppointment(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/pendingAppointment/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboardTopCard_totalDues(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/pendingDue/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_UpcomingAppointments(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/upcomingAppointment?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_appointmentRequest(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/appointmentRequest?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_rating(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/branchRating/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_averageRatingForGraph(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/averageRatingForGraph/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_patientsFeedback(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/getRating?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_approveOrRejectAppontment(body) {
  try {
    var res = await axios.patch(
      `${BASE_URL}users/approveOrRejectAppointment`,
      body
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_availableDentist(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/availableDentist/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getRoomListBasedOnBranchId(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/getRooms/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getPreviousChatsByRoomId(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/getChats?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getUnseenMessagesByClinicId(id) {
  try {
    var res = await axios.get(
      `${BASE_URL}users/unseenMessageCountForClinic/${id}`
    );
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getDashboard_CenterCharData(id) {
  try {
    var res = await axios.get(`${BASE_URL}users/chartData/${id}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function changeAppointmentstatus_schedule(body) {
  try {
    var res = await axios.patch(`${BASE_URL}users/completeAppointment`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

//   export async function forgotPasswordservice(body) {
//     try {
//       var res = await axios.patch(`${BASE_URL}auth/resetForgotPassword`, body);
//       return res;
//     } catch (error) {
//       throw new Error(error);
//     }
//   }

//   export async function storeListService(body) {
//     const data = objToQueryString(body);
//     try {
//       var res = await axios.get(`${BASE_URL}admin/get/store?${data}`);
//       return res;
//     } catch (error) {
//       throw new Error(error);
//     }
//   }

//   export async function deleteContactUsFunction(_id) {
//     try {
//       var res = await axios.delete(`${BASE_URL}admin/delete/contactUs/${_id}`);
//       return res;
//     } catch (error) {
//       throw new Error(error);
//     }
//   }

// Testing Purpose-
export async function addFunction(body) {
  try {
    var res = await axios.post(`${BASE_URL}admin/addContactUs`, {
      first_name: "David",
      last_name: "Hussy",
      email: "hussy@gmail.com",
      phone: "1234567890",
      address: "Australia",
      subject: "Back Ligh New",
      message: "Please change backlight of my car",
      reply: "",
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

//sayali
export async function sendrequest(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/requestNotToCharge`, body);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}
export async function getallpayments(branchId,data) {
   const param = objToQueryString(data);
  try {
    var res = await axios.get(`${BASE_URL}users/pendingAmount/${branchId}?${param}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function dentistlistforbilling(data) {
  //  const data = objToQueryString(body);
  try {
    var res = await axios.get(`${BASE_URL}users/dentistListInBranch/${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getinvoicelist(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/invoices/receipts?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}

export async function getappointmentlist(obj) {
  const data = objToQueryString(obj);
  try {
    var res = await axios.get(`${BASE_URL}users/showedUpAppointment?${data}`);
    return res.data;
  } catch (error) {
    errorNotification(error.response.data.errors.msg);
    throw new Error(error);
  }
}
// Universal Function-
export async function universalFun(method, route, data) {
  try {
    const url = `${BASE_URL}admin/${route}`;
    var res = await axios({ method, url, data });
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getsessionId(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/createCheckoutSession`, body);
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}
export async function addRatingAndFeedback(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/giveRating`, body);
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}
export async function sendQuery(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/addEnquiry`, body);
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}
export async function getAllClinicDentistSlots(
  branchId,
  day,
  date,
  dateString
) {
  try {
    var res = await axios.get(
      `${BASE_URL}users/getClinicSlots/${branchId}?day=${day}&date=${date}&dateString=${dateString}`
    );
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}
export async function getClicinRating() {
  try {
    var res = await axios.get(`${BASE_URL}users/giveRating`);
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}

export async function reseduleAppointment(body) {
  try {
    var res = await axios.post(`${BASE_URL}users/addSuggestedSlots`, body);
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}

export async function getSuggestedSlotsGlobal(params) {
  try {
    var res = await axios.get(`${BASE_URL}users/getSuggestedSlots`, {
      params: params,
    });
    return res;
  } catch (error) {
    errorNotification(error.message);
    throw new Error(error);
  }
}

//universal func fro http
export const makeHttpRequest = async (
  method,
  route,
  data = null,
  params = null
) => {
  const url = `${BASE_URL}${route}`;

  try {
    const response = await axios({
      method,
      url,
      data,
      params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// addFunction()

export async function generateImageFromName(
  name,
  size = 100,
  backgroundColor = "#ff6bb5",
  textColor = "#ffffff"
) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set canvas dimensions
    canvas.width = size;
    canvas.height = size;

    // Draw background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, size, size);

    // Draw text
    context.font = `${size / 2}px Arial`;
    context.fillStyle = textColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(name[0].toUpperCase(), size / 2, size / 2);

    // Convert canvas to blob
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error("Failed to convert canvas to blob");
        reject("Failed to convert canvas to blob");
        return;
      }

      // Create FormData object
      const formData = new FormData();
      formData.append("path", "userProfile");
      formData.append("media", blob);

      try {
        // Call the imageUpload function and await its response
        const resp = await imageUpload(formData);
        console.log("upload image response", resp);
        resolve(resp.path);
      } catch (error) {
        console.log("upload profile image error", error);
        reject(error);
      }
    });
  });
}

export const generateDeviceId = () => {
  // You can gather information from the user agent or other sources
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;

  return btoa(`${userAgent}-${platform}`);
};

export const submitContactUs = async (body) => {
  try {
    var res = await axios.post(`${BASE_URL}users/ContactUs`, body);
    return res;
  } catch (error) {
    // errorNotification(error.message);
    throw new Error(error);
  }
};

export * from "./httpService.js";
