import "./App.css";
import AllRoute from "./AllRoutes/AllRoute";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ChatSupport from "./components/ChatSupport/ChatSupport";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBar from 'react-top-loading-bar';
import Loader from "./components/Loader/Loader";
import { useLoadScript } from "@react-google-maps/api";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import loadTikTokPixel from "./lib/loadTikTokPixel";
import AbsoluteLoader from "./components/Loader/AbsoluteLoader";

// Define the schema manually for WebSite
const websiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Dentist Finder",
  "url": "https://www.dentistfinder.ai/",
  "potentialAction": [
    {
      "@type": "SearchAction",
      "target": "https://www.dentistfinder.ai/search?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  ],
  "about": {
    "@type": "Thing",
    "name": "Dental Services",
    "description": "Find the best dentists for your needs and criteria.",
  },
  "creator": {
    "@type": "Organization",
    "name": "Dentist Finder",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.dentistfinder.ai/logo192.png",
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-416-626-4652",
      "contactType": "Customer Support",
      "availableLanguage": "English",
    },
  },
  "hasPart": [
    {
      "@type": "WebPage",
      "name": "Find Your Perfect Dentist",
      "url": "https://www.dentistfinder.ai/",
    },
    {
      "@type": "WebPage",
      "name": "About Us",
      "url": "https://www.dentistfinder.ai/about",
    },
    {
      "@type": "WebPage",
      "name": "Login",
      "url": "https://www.dentistfinder.ai/login",
    },
    {
      "@type": "WebPage",
      "name": "Support",
      "url": "https://www.dentistfinder.ai/support",
    },
    {
      "@type": "WebPage",
      "name": "For Dentists",
      "url": "https://www.dentistfinder.ai/find-dentist",
    },
  ],
};

function App() {
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.loader.loading);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    // Load TikTok Pixel when the app mounts
    loadTikTokPixel();
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'ViewContent');
    }

    // Track page changes
    if (window.ttq) {
      window.ttq.page();
      window.ttq.track('PageView');
      window.ttq.track('ViewContent');
    }
  }, [location.pathname]);

  return (
    <Suspense fallback={<AbsoluteLoader loading={true} />}>
    <div className="App">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
        <meta
          name="description"
          content="Looking for a new dentist? Find one based on your needs and criteria. Plus, get rewarded after your first visit!"
        />
        <meta
          property="og:description"
          content="Looking for a new dentist? Find one based on your needs and criteria. Plus, get rewarded after your first visit!"
        />
      </Helmet>

      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
        <LoadingBar color="#1E90FF" progress={loading ? 100 : 0} />
        {/* <Loader loading={loading}/> */}
        <ToastContainer />
        <AllRoute />
        {/* {
          isAuthenticated && 
        <ChatSupport/>
        } */}
      </GoogleOAuthProvider>
    </div>
     </Suspense>
  );
}

export default App;
